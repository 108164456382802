import './assets/scss/style.scss'

/**
 *
 * VENDORS
 *
 */
import 'jquery'
//import 'bootstrap'


// Add event listener to table
$( ".regitrigger" ).on( "click", function() {
  console.log( $( this ).attr('id') );
  if ($( this ).attr('id') == 'newaccount') {
    $('#wpas_form_login').addClass('hidden').hide('fast');
    $('#wpas_form_registration, #hasaccount').removeClass('hidden').show('fast');
  } else {
    $('#wpas_form_login').removeClass('hidden').show('fast');
    $('#wpas_form_registration, #hasaccount').addClass('hidden').hide('fast');
  }
});




